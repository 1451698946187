.app-container {
    width: 1020px;
    margin: auto;
    display: flex;
    background-color: #ffffff;
}

.selection-box {
    width: 220px;
    padding: 15px 0px 0px 5px;
}

.quick-quote-box {
    margin-top: 10px;
    padding: 5px 10px 10px 20px;
    width: 880px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.quick-quote-header, .quick-quote-results {
    display: flex;
}

.quick-quote-header div,
.quick-quote-results div {
    width: 95px;
    text-align: center;
    height: 75px;
    font-size: 16px;
}

#root .monthly-repayment {
    width: 115px;
}

.quick-quote-header div {
    border-bottom: 1px solid #bdbec0;
}

.quick-quote-results div {
    border-bottom: 1px solid #bdbec0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.selection-header, .header-item {
    font-size: 18px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #69a2b9;
}


.quick-quote-label {
    font-size: 14px;
    font-weight: 300;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #69a2b9;
}

.select, .input {
    height: 38px;
    font-size: 17px;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal!important;
    letter-spacing: normal;
    color: #524f4f;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: 1px solid #333;
    border-radius: 0;
    width: 100%;
    outline: none;
    margin-bottom: 10px;
}

.select:focus {
    border-top: none;
    border-left: none;
    border-right: none;
    outline: none;
}

.input-group {
    display: table;
    border-bottom: 1px solid #333;
    margin-bottom: 10px;
}

.input {
    border-bottom: none;
    margin-bottom: 0px;
}

.input-group-addon {
    font-size: 17px;
}

.input-group-addon, .input {
    display: table-cell;
}

.action-buttons {
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    border-radius: 16px;
    background-color: #6aa0b8;
    padding: 10px 20px 10px 20px;
    display: inline-block;
    margin: 10px 0px 0px 10px;
    border: none;
    font-family: 'Dosis Medium';
}

.action-buttons:hover {
    cursor: pointer;
}

.required {
    border-bottom: 2px solid rgb(247, 4, 4);
}

.quick-quote-result-wrapper {
    height: 720px;
    overflow-y: scroll;
}

.loader {
    width: 100%;
    text-align: center;
    padding-top: 50px;
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
}

.lds-ripple div {
    position: absolute;
    border: 4px solid rgb(95, 129, 78);
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
  
.lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
}

@keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
}

.no-results {
    text-align: center;
    margin-top: 50px;
}

.apply-now-button {
    background-color: #6aa0b8;
    font-family: 'Dosis Medium';
    font-size: 15px;
    font-weight: 500;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #fff;
    padding: 5px 10px;
    outline: none;
    border: none;
    border-radius: 16px;
}

.apply-now-button:hover {
    cursor: pointer;
}

.lender-logo {
    width: 100px;
    padding-left: 10px;
}

.popper {
    width: 100px;
    text-align: center;
    margin-top: 5px;
    margin-bottom: 5px;
}

.popper:hover {
    cursor: pointer;
    color: #6aa0b8;
}

.sort:hover {
    cursor: pointer;
}

#root .info-icon {
    margin-left: 5px;
    color: #6aa0b8;
    width: 16px;
    height: 16px;
}

.info-icon:hover {
    cursor: pointer;
}

@media only screen and (max-width: 1020px) {
    .app-container {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
    }
    .selection-box {
        width: 100%;
        display: flex;
        flex-direction: column;
    }
    .selection-box-wrapper {
        display: flex;
        justify-content: center;
    }
    .selection-box-wrapper > div {
        margin: 10px;
    }
    .selection-box-section {
        width: 200px;
    }
    .action-buttons {
        margin: auto;
    }
}

@media only screen and (max-width: 768px) {
    .hide-sm, .quick-quote-results .hide-sm {
        display: none;
    }
    .quick-quote-box {
        width: 500px;
    }
}

@media only screen and (max-width: 500px) {
    .quick-quote-header div, .quick-quote-results div {
        width: 70px;
    }
    .quick-quote-box {
        width: 400px;
    }
    .lender-logo {
        width: 70px;
        padding-left: 20px;
    }
}
