.shopforcars-quick-quote {
    width: 100%;
    position: relative;
}

.quick-quote-section-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 170px;
}

.page-heading {
    color: #0d3c5f;
    position: absolute;
    top: 10px;
    font-family: 'Dosis Medium';
    font-size: 40px;
    font-weight: 600;
    letter-spacing: 1px;
    text-transform: uppercase;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.page-sub-heading {
    color: #0d3c5f;
    position: absolute;
    top: 70px;
    font-family: 'Dosis Medium';
    font-size: 32px;
    font-weight: 400;
    z-index: 1;
    text-align: center;
    width: 100%;
}

.shopforcars-quick-quote .selection-box {
    width: 500px;
    display: flex;
    flex-direction: column;
    margin-top: -80px;
}

.shopforcars-quick-quote svg {
    display: inline-block;
    position: absolute;
    top: 0;
    right: 0;
}

.shopforcars-quick-quote .selection-box-wrapper {
    display: flex;
    justify-content: center;
    z-index: 1;
    background-color: #ffffff;
    padding: 30px 30px 60px 30px;
    border-radius: 16px;
    border: 1px solid #B5D1DB;
}

.shopforcars-quick-quote .selection-box-wrapper > div {
    margin: 20px;
}

.shopforcars-quick-quote .selection-box-section {
    width: 200px;
}

.shopforcars-quick-quote input,
.shopforcars-quick-quote select,
.shopforcars-quick-quote .input-group {
    background-color: #eaf0f4;
    border-bottom: none;
    border-radius: 4px;
}

.update-results {
    z-index: 1;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
    color: #fff;
    border-radius: 6px;
    background-color: #0F3B62;
    padding: 10px 20px 10px 20px;
    margin: -60px 0px 0px 140px;
    width: 220px;
    border: none;
    font-family: 'Dosis Medium';
}

.update-results:hover {
    cursor: pointer;
}

.residual-balloon {
    position: relative;
}

.residual-balloon .input-group-addon {
    padding-right: 20px;
}

.residual-balloon .info-icon {
    top: 12px;
}

.choose-from-left-section {
    background-color: #0F3B62;
    border-radius: 20px;
    padding-top: 50px;
    width: 360px;
    height: 360px;
    z-index: 1;
    margin-right: 30px;
}

.quick-quote-bottom-section .choose-from-left-section h1 {
    font-family: 'Dosis Medium';
    font-weight: 600;
    font-size: 34px;
    padding: 0 50px;
    text-align: center;
    color: #ffffff;
    margin: 0px 14px 25px 14px;
}

.shopforcars-quick-quote  .quick-quote-box {
    z-index: 1;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 16px;
    border: 1px solid #B5D1DB;
    margin-top: 0px;
    width: 820px;
    margin-bottom: 50px;
    margin-left: 15px;
}

.shopforcars-quick-quote  .quick-quote-box .quick-quote-header div {
    border-bottom: none;
}

.shopforcars-quick-quote .quick-quote-results div {
    border-bottom: none;
    height: 60px;
}

.shopforcars-quick-quote .lender-logo {
    width: 90px;
}

.shopforcars-quick-quote .apply-now-button {
    color: #fff;
    border-radius: 6px;
    background-color: #0F3B62;
    padding-left: 15px;
    padding-right: 15px;
}

.shopforcars-quick-quote .quick-quote-result-wrapper {
    height: 335px;
}

.showScrollbar::-webkit-scrollbar {
    width:8px;
}

.showScrollbar::-webkit-scrollbar-thumb{
    border-radius: 10px;
    background-color: #A5C5D1;
    box-shadow: inset 0 0 6px rgba(0,0,0,.5);
}

.showScrollbar::-webkit-scrollbar-track{
    border-radius: 10px;
    background-color: #e8f4f8;
    box-shadow: inset 0 0 6px rgba(0,0,0,.4);
}

.header-item {
    position: relative;
}

.header-item .info-icon {
    top: 20px;
}
