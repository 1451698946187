.customer-info-modal {
    width: 480px;
    border-radius: 10px;
    border: none;
}

.customer-info-modal:focus {
    outline: none;
}

.modal-title {
    font-weight: 600;
    transition: none 0s ease 0s;
    line-height: 24px;
    border-width: 0px;
    margin: 20px 0px 15px;
    padding: 0px;
    letter-spacing: 0px;
    font-size: 25px;
    font-family: 'Dosis Medium';
    color: #333;
    text-align: center;
}

.modal-input-group, .customer-info-modal .modal-select {
    width: 450px;
}

.compare-lenders {
    width: 450px;
}

.customer-info-modal.modal-submitted {
    background-color: rgb(48, 199, 48);
}

.modal-submitted .modal-title {
    color: #ffffff;
}

.modal-submitted-text {
    color: #ffffff;
    text-align: center;
}

@media only screen and (max-width: 500px) {
    .modal-input-group, .customer-info-modal .modal-select {
        width: 320px;
    }
    
    .compare-lenders {
        width: 320px;
    }    
}
