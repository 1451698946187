.top-banner {
    width: 100%;
}

.top-banner .top-banner-left-section {
    position: absolute;
    z-index: 1;
    margin-top: 200px;
    left: 0;
    width: 40%;
}

.top-banner-left-section h1 {
    font-size: 44px;
    text-align: left;
    font-weight: 400;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #1d496a;
    margin: 0px;
    text-align: right;
}

.shopforcars-quick-quote .app-container {
    width: 1250px;
}

.shopforcars-quick-quote .action-buttons {
   width: 200px;
   margin-left: 100px;
}

.shopforcars-quick-quote .input-field {
    width: calc(100% - 4px);
}

.lenders-banner-wrapper {
    position: relative;
}

.lender-banner-image {
    background-image: url('./../../assets/images/background.jpeg');
    background-position: bottom;
    background-repeat: no-repeat;
    background-size: cover;
    height: 500px;
}

.lender-banner-boy-scooter {
    position: absolute;
    animation: 5s ease-out 0s 1 slideInFromLeft;
    top: 100px;
    width: 340px;
    left: 0px;
    z-index: 99;
}

.arrow-image {
    position: absolute;
    top: 100px;
    width: 45%;
}
.lender-banner-details {
    border-radius: 16px;
}

h2.section-title {
    font-family: "Dosis Light";
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0d3c5f;
    text-transform: uppercase;
    text-align: center;
}

.lender-banner-details h2 {
    font-family: 'Dosis Light';
    font-size: 32px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0F3B62;
    text-transform: uppercase;
    text-align: center;
    margin: 2px;
}

.justify-content-center {
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    display: flex;
}

@keyframes slideInFromLeft {
    0% {
      transform: translateX(-100%);
    }
    100% {
      transform: translateX(0);
    }
}

.button-wrapper {
    position: absolute;
    bottom: -22px;
    margin: 0 10px;
    width: 100%;
    left: 0px;
}

.top-banner a, 
.lender-banner-details button, 
.lender-banner .lenders-btn-blue {
    border-radius: 6px;
    width: 230px;
    padding: 10px 10px;
    background-color: #6aa2b8;
    transition: 0.5s ease-in-out;
    text-align: center;
    color: #ffffff;
    font-size: 15.5px;
    font-family: 'Open Sans', sans-serif, Arial;
    text-decoration: none;
    margin: 0px 10px 0px 10px;
}

.lenders-btn-blue {
    border: none;
}

.lenders-btn-blue:hover {
    cursor: pointer;
}

.grey-bg {
    background-color: #efefef;
    padding-bottom: 40px;
}

.pre-approved-content {
    width: 1080px;
    margin: auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding-top: 50px;
}

.pre-approved-wrapper {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
}

.pre-approved-wrapper .left-details ul {
    margin-top: 0px;
}

.pre-approved-wrapper li, .right-details p {
    font-family: 'Open Sans', sans-serif, Arial;
    font-size: 14px;
    line-height: 24px;
}

.pre-approved-right-section .choose-from-left-section h1 {
    line-height: 30px;
    font-weight: 600;
}

.choose-from-left-section-span {
    font-size: 100px;
    line-height: 100px;
}

.pre-approved-timeline {
    width: 440px;
    margin: auto;
}

.pre-approved-timeline li {
    list-style: none;
    font-family: 'Open Sans', sans-serif, Arial;
    font-size: 14px;
}

figure {
    display: block;
    margin-bottom: 0px;
}

.figure-img {
    width: 100px;
}

.timeline ul {
    width: 275px;
}

.timeline h3 {
    font-family: 'Dosis Light';
    font-size: 20px;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #6aa2b8;
    text-transform: uppercase;
    text-align: left;
    margin-bottom: 5px;
    width: 200px;
}

.figure-text-wrapper {
    display: flex;
}

.figure-left-margin {
    margin-left: 80px;
}

.animation-element {
    padding: 0px;
}

.animation-element li::before {
    color: #c9baba;
    content: "\2713";
    font-size: 18px;
}

.pre-approved-timeline, .apply-section, .contact-form {
    padding: 20px 0 35px 0;
}

.apply-section {
    margin-top: -10px;
    background-color: #eaf0f4;
    padding: 0px;
}

.apply-section-bottom-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.apply-section-actions {
    display: flex;
}

.apply-section-actions a {
    text-decoration: none;
}

.apply-now-svg {
    margin-top: -170px;
}

.apply-wrapper {
    width: 330px;
    display: flex;
    float: left;
    text-align: center;
    padding: 20px 30px 0px 30px;
    border: 2px solid #e0e0e0;
    margin: 10px;
    flex-wrap: wrap;
    transition: 0.5s ease-in-out;
    flex-direction: column;
    background-color: #0F3B62;
    color: #ffffff;
    border-radius: 16px;
}

.apply-wrapper:hover {
    cursor: pointer;
    border: 2px solid #0d3c5f;
    transition:0.5s ease-in-out;
}

.apply-wrapper figure img {
    height: 48px;
    transition: transform 0.5s ease;
}

.apply-wrapper:hover figure img {
    transform: scale(1.3);
}

.apply-wrapper h3 {
    font-size: 24px;
    font-style: normal;
    font-stretch: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    text-transform: uppercase;
    margin-bottom:5px;
}

.apply-wrapper p {
    color: #ffffff;
    font-family: 'Open Sans', sans-serif, Arial;
    margin-bottom: 20px;
    font-size: 14px;
}

.contact-form {
    padding: 0px;
    margin-top: -200px;
}

.contact-form-container {
    width: 740px;
    margin: auto;
    margin-bottom: 20px;
}

.contact-form-heading-subtext {
    font-family: 'Dosis Light';
    font-size: 20px;
    font-weight: 600;
    color: #6aa2b8;
    text-align: center;
}

.contact-form-container input, .contact-form-container textarea {
    height: 50px;
    border: 1px solid #eaf0f4 !important;
    border-radius: 6px;
    width: calc(100% - 25px);
    margin: 5px;
    padding-left: 10px;
    font-family: 'Open Sans', sans-serif, Arial;
    background-color: #eaf0f4;
}

.contact-form-container textarea {
    height: 120px;
}

.contact-form-container input:focus, .contact-form-container textarea:focus  {
    outline: none;
    border: none;
}

.form-input-group input {
    width: 50%;
}

.form-input-group {
    display: flex;
}

.contact-us-button {
    border: none;
    border-radius: 6px;
    width: 230px;
    padding: 10px 10px;
    background-color: #0F3B62;
    transition: 0.5s ease-in-out;
    text-align: center;
    color: #ffffff;
    font-size: 15.5px;
    font-family: 'Dosis Medium';
    text-decoration: none;
    margin: 0px 10px 0px 10px;
}

.contact-form .contact-us-button:hover {
    cursor: pointer;
}

.right-details {
    flex-basis: 100%;
    padding-left: 35px;
}

.lender-banner-details {
    position: absolute;
    width: 575px;
    background-color: rgba(255, 255, 255, 0.6);
    right: 5%;
    top: -40px;
    padding: 35px;
    transform: translateY(20%);
}

.footer {
    font-family: 'Open Sans', sans-serif, Arial;
    font-size: 12px;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 20px;
}

.footer a {
    text-decoration: none;
    color: #0F3B62;
}

@media screen and (max-width: 1350px) {
    .pre-approved-content {
        width: 767px;
    }
    .shopforcars-quick-quote .page-heading {
        font-size: 36px;
    }
    .shopforcars-quick-quote .page-sub-heading {
        font-size: 28px;
    }
    .quick-quote-section-wrapper {
        flex-direction: column;
    }
    .shopforcars-container .shopforcars-quick-quote .selection-box {
        margin-top: 0px;
    }
    .shopforcars-container .shopforcars-quick-quote .quick-quote-box {
        margin-left: 0px;
        margin-top: 30px;
    }
    .arrow-image {
        display: none;
    }
}

@media screen and (max-width: 1024px) {
    .shopforcars-quick-quote .selection-box {
        margin: auto;
        margin-left: auto !important;
    }
    .shopforcars-container .shopforcars-quick-quote .quick-quote-box {
        width: 800px;
    }
    .apply-section .apply-wrapper {
        width: 250px;
    }
    .shopforcars-quick-quote .page-heading {
        font-size: 26px;
    }
    .shopforcars-quick-quote .page-sub-heading {
        font-size: 22px;
        top: 45px;
    }
    .shopforcars-quick-quote .quick-quote-section-wrapper {
        padding-top: 90px;
    }
    .lender-banner-boy-scooter {
        display: none;
    }
    .shopforcars-container .lender-banner-details {
        right: calc(50% - 320px);
    }
}

@media screen and (max-width: 800px){
    .apply-section-actions .apply-wrapper {
        width: 170px;
    }
    .lender-banner-details p {
        font-size: 14px;
    }
    .pre-approved-section .section-title, 
    .lender-banner-details h2, 
    h2.section-title,
    .shopforcars-container {
        font-size: 26px;
    }
    .shopforcars-container h3 {
        font-size: 18px;
    }
    .pre-approved-right-section .choose-from-left-section h1 {
        font-size: 26px;
    }
    .choose-from-left-section-span {
        font-size: 75px;
    }
    .top-banner-left-section h1 {
        font-size: 32px;
    }
    .contact-form-container {
        width: 350px;
    }
    .form-input-group {
        display: block;
    }
    .form-input-group input {
        width: calc(100% - 25px);
    }
    .pre-approved-content {
        width: 100%;
        flex-direction: column;
    }
    .pre-approved-wrapper {
        flex-direction: column;
        padding: 10px;
    }
    .choose-from-left-section {
        margin: auto;
    }
}

@media screen and (max-width: 768px){
    .shopforcars-container .shopforcars-quick-quote .quick-quote-box {
        width: 720px;
    }
    .shopforcars-quick-quote .quick-quote-box {
        width: 500px !important;
    }
    .shopforcars-container .lender-banner-details {
        width: 340px;
        right: calc(50% - 200px);
    }
    .lender-banner-image {
        height: 630px;
    }
    .shopforcars-container .lender-banner-details {
        top: -100px;
    }
    .shopforcars-quick-quote .page-heading {
        font-size: 22px;
    }
}

@media screen and (max-width: 520px) {
    .apply-section-actions {
        flex-direction: column;
    }
    .pre-approved-timeline {
        width: 340px;
    }
    .timeline {
        padding-left: 0px;
    }
    .pre-approved-timeline .figure-img img {
        width: 60px;
    }
    .top-banner .left-content .section-title {
        width: 80%;
    }
    .top-banner .left-content .section-title, .top-banner-left-section h1 {
        font-size: 22px;
    }
    .top-banner .top-banner-left-section {
        width: 80%;
        margin-top: 80px;
    }
    #root .shopforcars-quick-quote .selection-box-wrapper {
        margin-top: 20px;
        padding: 30px 2px 60px 2px;
    }
    .shopforcars-quick-quote .selection-box-wrapper > div {
        margin: 5px !important;
    }
    .shopforcars-quick-quote .selection-box {
        width: 380px !important;
    }
    .shopforcars-quick-quote .quick-quote-box {
        width: 370px !important;
    }
    .shopforcars-quick-quote .selection-box-section {
        width: 180px !important;
    }
    .shopforcars-quick-quote .update-results {
        margin: -60px 0px 0px 80px;
    }
    .contact-form {
        margin-top: -120px;
    }
    .shopforcars-quick-quote .lender-logo {
        width: 70px !important;
    }
    .lender-banner-details p {
        font-size: 14px;
    }
    .shopforcars-quick-quote .page-sub-heading {
        top: 60px;
    }
    .shopforcars-quick-quote .page-sub-heading {
        font-size: 18px;
        top: 70px;
    }
    .quick-quote-header div, .quick-quote-results div {
        font-size: 12px;
    }
    #root .monthly-repayment {
        width: 80px;
    }
    .shopforcars-quick-quote .lender-logo {
        margin-right: 10px;
    }
    .lender-banner-details {
        padding: 20px;
    }
    .shopforcars-container .lender-banner-details {
        width: 340px;
        right: calc(50% - 190px);
    }
}

@media screen and (max-width: 400px) { 
    .shopforcars-quick-quote .selection-box {
        width: 366px !important;
    }
    .shopforcars-quick-quote .quick-quote-box {
        width: 320px !important;
        margin-left: 8px;
    }
    .shopforcars-quick-quote .apply-now-button {
        padding-left: 2px !important;
        padding-right: 2px !important;
    }
    .quick-quote-header div, .quick-quote-results div {
        width: 60px;
    }
    .lender-banner-details h2, h2.section-title {
        font-size: 20px;
    }
    .shopforcars-container .lender-banner-details {
        width: 300px;
        right: calc(50% - 170px);
    }
    .shopforcars-quick-quote .input-value {
        width: 150px;
    }
}
