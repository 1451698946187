@font-face { 
    font-family:'Dosis Medium';
    src: url('./assets/fonts/Dosis-Medium.ttf') 
    format("TrueType")
}

@font-face { 
    font-family:'Dosis Bold';
    src: url('./assets/fonts/Dosis-Bold.ttf') 
    format("TrueType")
}

@font-face { 
    font-family:'Dosis Light';
    src: url('./assets/fonts/Dosis-Light.ttf') 
    format("TrueType")
}

@font-face { 
    font-family:'Dosis SemiBold';
    src: url('./assets/fonts/Dosis-SemiBold.ttf') 
    format("TrueType")
}

@font-face { 
    font-family:'Open Sans Bold';
    src: url('./assets/fonts/OpenSans-Bold.ttf') 
    format("TrueType")
}

@font-face { 
    font-family:'Open Sans';
    src: url('./assets/fonts/OpenSans-Regular.ttf') 
    format("TrueType")
}

@font-face { 
    font-family:'Open Sans SemiBold';
    src: url('./assets/fonts/OpenSans-Semibold.ttf') 
    format("TrueType")
}

body {
    margin: 0;
    font-family: 'Dosis Medium';
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #ffffff;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',monospace;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type=number] {
    -moz-appearance:textfield;
}

::-webkit-scrollbar {
    width: 0px;
    background: transparent;
}

.form-error {
    color: red;
    font-size: 12px;
    font-style: italic;
}

p {
    margin-top: 1px;
    margin-bottom: 1px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
    transition-delay: background-color 5000s, color 5000s;
}
